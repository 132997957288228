import React from "react";

import { graphql } from "gatsby";
import { getImage, GatsbyImage as Img } from "gatsby-plugin-image";

import { Card, LinkList } from "@parataxic/shared-ui";

import { ListView } from "../components/ListView";
import { PopoverSiteLink } from "../components/Popover";

const ViewItem: React.FC<{
  item: GatsbyTypes.BookMediaItemFragment;
}> = ({ item }) => (
  <Card
    style={{
      display: "grid",
      gridTemplateColumns: "2fr 10fr",
      gridColumn: "5/6",
      gridGap: "1rem",
      margin: "1rem",
    }}
  >
    <div
      style={{
        minWidth: "195px",
        gridTemplateColumns: "2fr", // 1/10 on desktop
        gridGap: "1rem",
        position: "relative",
        justifySelf: "center",
        width: "100%",
        display: "grid",
      }}
    >
      <Img image={getImage(item?.coverImage)} alt={item.title} />
    </div>
    <div
      style={{
        gridTemplateColumns: "1fr",
        display: "grid",
      }}
    >
      <PopoverSiteLink
        href={item.slug}
        style={{
          width: "100%",
          color: "var(--color-gray-800)",
          fontWeight: "500",
          display: "inline-table",
        }}
      >
        {item.title}
      </PopoverSiteLink>
      {item.mentionedTheories && (
        <div
          style={{
            gridColumn: "1/2",
            textAlign: "right",
            alignSelf: "end",
            fontSize: "0.8rem",
          }}
        >
          <LinkList
            links={item.mentionedTheories}
            Component={PopoverSiteLink}
          />
        </div>
      )}
      {item.authors && (
        <div
          style={{
            textAlign: "right",
            alignSelf: "end",
            gridColumn: "none",
            fontSize: "0.8rem",
          }}
        >
          <LinkList links={item.authors} Component={PopoverSiteLink} />
        </div>
      )}
      {item.mentionedPsychologists && (
        <div
          style={{
            textAlign: "right",
            alignSelf: "end",
            gridColumn: "none",
            fontSize: "0.8rem",
          }}
        >
          <LinkList
            links={item.mentionedPsychologists}
            Component={PopoverSiteLink}
          />
        </div>
      )}
    </div>
  </Card>
);

const View = ({
  data,
}: {
  data: GatsbyTypes.BookMediaListQuery;
}): React.ReactNode => {
  return (
    <ListView<GatsbyTypes.BookMediaItemFragment>
      edges={data.allMdxBook.edges}
      ListItem={ViewItem}
      seoProps={{
        title: "Read psych papers, download books directly",
      }}
    />
  );
};

export const mediaQuery = graphql`
  fragment BookMediaItem on MdxBook {
    id
    title
    slug
    authors {
      id
      handle
      slug
    }
    mentionedTheories {
      id
      title
      slug
    }
    mentionedPsychologists {
      id
      handle
      slug
    }
    coverImage {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
  }

  query BookMediaList {
    allMdxBook {
      edges {
        node {
          ...BookMediaItem
        }
      }
    }
  }
`;

export default View;
